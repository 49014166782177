<template>
  <div class="row rest">
    <div class="rest aside-bg"></div>
    <ThinqApiSnb class="left-container" />
    <article class="right-container">
      <hgroup>
        <h1 id="device_query">가전 상태 조회</h1>
        <div class="note">사용자의 가전 상태를 조회하는 방법을 설명합니다.</div>
      </hgroup>

      <h2>사용할 API</h2>
      <ul class="list-style-none">
        <li>
          <router-link to="/docs/thinq/reference#device-li"
            >GET /devices</router-link
          >
        </li>
        <li>
          <router-link to="/docs/thinq/reference#device-cond"
            >GET /devices/{device-id}</router-link
          >
        </li>
      </ul>
      <h2>Sequence</h2>
      <div>
        <ol>
          <li>
            먼저 가전 목록 조회 API(<router-link
              to="/docs/thinq/reference#device-li"
              >GET /devices</router-link
            >)를 이용하여, LG ThinQ 플랫폼에 등록된 사용자의 가전 목록을
            가져와야 합니다. 이 과정은 처음 한 번만 수행하면 되고, 목록을 한 번
            가져온 후에는 매번 수행할 필요는 없습니다.
          </li>
          <li>
            가전 목록에서 상태를 조회할 디바이스의 device-id 값을 확인하고, 이
            값을 이용하여 가전 상태 조회 API(<router-link
              to="/docs/thinq/reference#device-cond"
              >GET /devices/{device-id}</router-link
            >)를 호출합니다.
          </li>
        </ol>
      </div>
      <img src="/assets/img/thinq1.png" alt="" />
      <hgroup>
        <h1 id="device_control">가전 제어</h1>
        <div class="note">사용자의 가전을 제어하는 방법을 설명합니다.</div>
      </hgroup>

      <h2>사용할 API</h2>
      <ul class="list-style-none">
        <li><router-link to="/docs/thinq/reference#device-li">GET /devices</router-link></li>
        <li><router-link to="/docs/thinq/reference#device-pro">GET /devices/profile/{device-id}</router-link></li>
        <li><router-link to="/docs/thinq/reference#device-cont">POST /devices/{device-id}</router-link></li>
      </ul>
      <h2>Sequence</h2>
      <div>
        <ol>
          <li>
            먼저 가전 목록 조회 API(<router-link to="/docs/thinq/reference#device-li">GET/devices</router-link>)를 이용하여, LG ThinQ 플랫폼에
            등록된 사용자의 가전 목록을 가져와야 합니다. 이 과정은 처음 한 번만
            수행하면 되고, 목록을 한 번 가져온 후에는 매번 수행할 필요는
            없습니다.
          </li>
          <li>
            가전 목록에서 제어 대상 디바이스의 device-id 값을 확인하고, 이 값을
            이용하여 가전 프로파일 조회 API(<router-link to="/docs/thinq/reference#device-pro">GET /devices/profile/{device-id}</router-link>)를
            호출합니다.
          </li>
          <li>
            호출 응답으로 받은 가전 프로파일을 바탕으로 해당 디바이스에 대한
            제어 명령을 생성합니다. 제어 명령은 가전 프로파일에서 제어를 원하는
            속성을 찾아 name과 value를 쌍으로 표현합니다.
          </li>
          <li>
            device-id와 제어 명령을 이용하여 가전 제어 API(<router-link to="/docs/thinq/reference#device-cont">POST
            /devices/{device-id}</router-link>)를 호출합니다.
          </li>
          <li>API 응답으로 가전 제어 결과를 반환받습니다.</li>
        </ol>
      </div>
      <img src="/assets/img/thinq2.png" alt="" />
    </article>
    <div class="rest"></div>
  </div>
</template>
<script>
import ThinqApiSnb from "./ThinqApiSnb.vue";
export default {
  components: {
    ThinqApiSnb,
  },
};
</script>
